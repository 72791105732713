import * as React from "react";
import Layout from "@arbol/Layout";
import Seo from "@components/seo";
import * as styles from "./index.module.scss";
import classNames from "classnames";
import LogoFancy from "@images/arbol/landing-page/logo-fancy.svg";
import LogoTinyA from "@images/arbol/landing-page/logo-tiny-a.svg";
import DiscoverArbol from "@images/arbol/landing-page/discover-arbol.svg";
import MobileLogo from "@images/arbol/landing-page/mobile-logo.svg";
import favicon from "@images/arbol/favicon.png";
import { Decor1, Decor2, Decor3 } from "@images/arbol/landing-page/decor";
import LiveWorkPlay from "@images/arbol/landing-page/live-work-play.svg";
import BottomDecor from "@images/arbol/landing-page/bottom-decor1.svg";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import UnitFilter from "@arbol/UnitFilter";
import Amenities from "@arbol/Amenities";
import TheNeighborhood from "@arbol/TheNeighborhood";
import News from "@arbol/News";
import ContactForm from "@arbol/ContactForm";
import Footer from "@arbol/Footer";
import RellaxWrapper from "react-rellax-wrapper";

const rellaxProps = {
  xs: -1,
  mobile: -2,
  tablet: -2,
  desktop: -3,
  breakpoints: [500, 1064, 1377],
  className: styles.noPointer,
};

const IndexPage = () => {
  const handleScroll = () => {
    const elementList = Array.from(
      document.getElementsByClassName(styles.fadeInUp)
    );
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.4) {
          entry.target.classList.add(styles.isVisible);
        }
      });
    });

    elementList.forEach(el => observer.observe(el));
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Layout>
      <Seo
        title="Arbol by Gomes | Newark, NJ"
        icon={favicon}
        description="Arbol by Gomes, Newark New Jersey’s newest modern apartment concept inspired by nature and built for more than living."
      />
      <div className={styles.container}>
        <div className={styles.banner}>
          <div className={classNames(styles.titleContainer, styles.fadeInUp)}>
            <span className={styles.companyName}>
              Arbol by Gomes | Newark, New Jersey
            </span>
            <span className={classNames(styles.companyName, styles.mobile)}>
              Arbol by Gomes
              <br />
              Newark, New Jersey
            </span>
            <span className={styles.title}>More Than Just</span>
            <div className={styles.divider} />
            <span className={classNames(styles.title, styles.big)}>Living</span>
          </div>
          <div className={styles.logoFancy}>
            <LogoFancy />
          </div>
        </div>
        <div
          className={classNames(
            styles.relativeWrapper,
            styles.green,
            styles.topSection
          )}
        >
          <Decor1 className={styles.decor} />
          <Decor2 className={styles.decor} />
          <Decor3 className={styles.decor} />
          <StaticImage
            src="../../../images/arbol/landing-page/decor16.png"
            className={styles.decor}
            placeholder="None"
            loading="eager"
            alt="decor"
          />
          <RellaxWrapper
            {...rellaxProps}
            style={{
              position: "absolute",
              top: "-280px",
              left: "-307.23px",
              width: "1080.49px",
              height: "1437.16px",
              transform: "rotate(-338deg)",
            }}
          >
            <StaticImage
              src="../../../images/arbol/landing-page/decor4.png"
              placeholder="None"
              loading="eager"
              alt="decor"
            />
          </RellaxWrapper>
          <DiscoverArbol className={styles.discoverArbol} />
          <div className={classNames(styles.discoverArbol, styles.mobile)}>
            <span>DISCOVER</span>
            <MobileLogo className={styles.mobileLogo} />
          </div>
          <RellaxWrapper
            {...rellaxProps}
            style={{
              position: "absolute",
              top: "486px",
              left: "-235px",
              width: "2414.3px",
              height: "2335.9px",
            }}
          >
            <StaticImage
              src="../../../images/arbol/landing-page/bg-photo2.png"
              placeholder="None"
              loading="eager"
              alt="decor"
            />
          </RellaxWrapper>
        </div>
        <div className={classNames(styles.relativeWrapper, styles.green)}>
          <div className={styles.foregroundContainer}>
            <div className={classNames(styles.liveWorkPlay, styles.fadeInUp)}>
              <LiveWorkPlay />
              <p>
                Apartment living designed to nourish your mind, body and soul.
                <br />
                <br />
                We believe that in order to live fully, you must be deeply
                rooted and our nature inspired concept will empower your growth
                as well as foster community.
                <br />
                <br />
                Arbol was planted as a seed for change and we are invested in
                seeing it become a vibrant forest that is full of life.
              </p>
            </div>
            <div className={styles.branchContainer}>
              <RellaxWrapper {...rellaxProps} className={styles.stumpBg}>
                <StaticImage
                  src="../../../images/arbol/landing-page/bg-photo1.png"
                  placeholder="None"
                  loading="eager"
                  alt="decor"
                />
              </RellaxWrapper>
              <StaticImage
                className={styles.branchImage}
                src="../../../images/arbol/landing-page/fg-photo1.png"
                placeholder="None"
                loading="eager"
                alt="photo of tree branch"
              />
            </div>
          </div>
        </div>
        <div className={classNames(styles.relativeWrapper, styles.green)}>
          <StaticImage
            className={styles.boxerImage}
            src="../../../images/arbol/landing-page/fg-photo2.png"
            placeholder="None"
            loading="eager"
            alt="photo of boxer"
          />
          <StaticImage
            src="../../../images/arbol/landing-page/decor15.png"
            className={styles.decor}
            placeholder="None"
            loading="eager"
            alt="decor"
          />
          <StaticImage
            className={classNames(styles.inkSplash, styles.decor)}
            src="../../../images/arbol/landing-page/ink-splash1.png"
            placeholder="None"
            loading="eager"
            alt="photo of boxer"
          />
          <StaticImage
            className={styles.decor}
            style={{ bottom: '-665px', right: '72%', width: '1126px', zIndex: 1, pointerEvents: 'none' }}
            src="../../../images/arbol/landing-page/decor8.png"
            placeholder="None"
            loading="eager"
            alt="decor"
          />
          <div className={classNames(styles.garyQuote, styles.fadeInUp)}>
            <span>“</span>
            <span className={styles.first}>Nature</span>
            <span className={styles.second}>is not a place</span>
            <span className={styles.third}>to visit.</span>
            <span className={styles.fourth}>It is home.</span>
            <span className={styles.signature}>- GARY SNYDER</span>
            <span>&rdquo;</span>
          </div>
          <BottomDecor className={styles.bottomDecor} />
        </div>
        <div className={styles.relativeWrapper}>
          <div
            className={classNames(
              styles.foregroundContainer,
              styles.elevatedContainer
            )}
          >
            <div
              className={classNames(styles.elevatedContent, styles.fadeInUp)}
            >
              <span>LIFE AT ARBOL</span>
              <h2>Your space, elevated.</h2>
              <p>
                Arbol by Gomes is a green luxury apartment concept that feels
                like a boutique hotel. Spacious and masterfully designed, it
                offers a tranquil respite in your day to day. Every apartment is
                uniquely outfitted, featuring natural hardwood flooring, quartz
                countertops, stainless steel appliances and lots of natural
                light. We have utilized smart technology systems that sync
                locks, lights, intercom and music so you have everything you
                need at your fingertips.
              </p>
            </div>
            <StaticImage
              className={styles.lightbulbImage}
              src="../../../images/arbol/landing-page/fg-photo3.jpg"
              placeholder="None"
              loading="eager"
              style={{ marginTop: "235px", opacity: 0.87, height: "574px" }}
              alt="home interior"
            />
            <StaticImage
              className={styles.woodBg}
              src="../../../images/arbol/landing-page/bg-photo3.jpg"
              placeholder="None"
              loading="eager"
              alt="decor"
            />
            <StaticImage
              src="../../../images/arbol/landing-page/decor6.png"
              style={{
                position: "absolute",
                top: "300px",
                left: "26%",
                width: "1425px",
                height: "1052px",
                zIndex: -1,
              }}
              placeholder="None"
              loading="eager"
              alt="decor"
            />
            <div className={styles.sideLine}>
              <span>UNITS AVAILABLE</span>
              <div className={styles.line} />
              <LogoTinyA />
            </div>
          </div>
        </div>
        <div className={styles.relativeWrapper}>
          <UnitFilter />
          <StaticImage
            className={styles.decor}
            style={{
              top: "450px",
              left: "62%",
              width: "866px",
              height: "999px",
              opacity: 0.49,
            }}
            src="../../../images/arbol/landing-page/decor7.png"
            placeholder="None"
            loading="eager"
            alt="decor"
          />
        </div>
        <Amenities />
        <TheNeighborhood />
        <News />
        <ContactForm />
        <div className={styles.relativeWrapper} style={{ padding: 0 }}>
          <Footer style={{ marginTop: "104px" }} />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
